<template>
    <div class="page-warp">
        <!--<div class="row con-img">
            &lt;!&ndash;<van-swipe indicator-color="white" v-if="Alldata.pictureUrl">
                <van-swipe-item v-for="(picItem,picInx) in Alldata.pictureUrl.split(',')" :key="picInx">
                    <div class="swiper-imgs"><img :src="picItem" /></div>
                </van-swipe-item>
            </van-swipe>&ndash;&gt;
            <div class="swiper-imgs" v-else>
                <img src="../../assets/images/product.png" />
            </div>
        </div>-->

        <div class="row policy-detail-title mt-20">{{Alldata.title}}</div>
        <div class="row policy-detail-date">发布时间: {{Alldata.createTime}}</div>
        <div class="content-warp mt-10">
            <div class=" editor ql-container">
                <div class="ql-editor" v-html="Alldata.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { findById } from "@/api/policy";
import "../../assets/css/quill.core.css";
import "../../assets/css/quill.snow.css";
import "../../assets/css/quill.bubble.css";
export default {
    name:'ServiceDetail',
    data() {
        return {
            orderId:'',
            Alldata:{},
        };
    },
    created() {
        this.orderId = this.$route.query.id;
        this.findAll();
    },
    methods: {
        //   通过id查询订单信息
        async findAll() {
            let res = await findById(this.orderId);
            this.Alldata = res.data;
        },
    },
};
</script>
<style lang="less" scope>
    .con-img {
        position: relative; z-index: 1;
        height: 300px;
        .swiper-imgs {
            position: relative; z-index: 1;
            height: 300px; 
        }
        img {
            position: absolute; left: 50%; top:50%;
            min-width: 100%; min-height:100%;
        }
    }
    .page-model-title {
        text-align: left; line-height: 40px; font-size: 16px; color: #080808;
        box-sizing: border-box; padding:0px 10px;
    }
    .content-warp {
        box-sizing: border-box;
        padding:0px 10px;
        img { width: 100%;}
    }

.policy-detail-title {
    padding-top:12px;
    font-size: 18px;line-height: 26px; font-weight: 600;
    box-sizing: border-box; text-align: center;
    padding:0px 10px; 
}
.policy-detail-date {
    font-size:11px; color: #080808; opacity: 0.4; line-height: 18px;
    box-sizing: border-box;
    padding:0px 10px;
}
</style>